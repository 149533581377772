import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import room from "@/composable/api/room";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:id",
    name: "chat",
    component: () => import("./views/Main.vue"),
  },
  {
    path: "/test/:id",
    name: "chatTest",
    component: () => import("./views/MainTest.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("./views/404.vue"),
  }
];

const {checkRoomExist} = room;

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "chat") {
    await checkRoomExist(to.params.id)
        .catch((error) => {
          next('/404');
        })
  }
  if (to.name === "chatTest") {
    if (process.env.NODE_ENV !== 'development') {
      next('/404');
    }
  }
  next();
});

export default router;
