import {defineStore} from 'pinia';
import {useSocket} from "@/stores/socket";
import dialog from "@/composable/form/dialog";
import {callEvent, setPeer} from "@/composable/peerjs";
import {userId, mediaStreamId} from "@/composable/peerjs";

const {dialogError} = dialog

export const useMediaDevicesStore = defineStore('mediaDevice', {
    state: () => ({
        videos: [] as any,
        myVideo: ''
    }),
    getters: {
        getVideos(state) {
            return state.videos;
        },
        getMyVideo(state) {
            return state.myVideo;
        }
    },
    actions: {
        addVideoStream(stream, first:boolean|false) {
            if(first) {
                this.myVideo = stream;
            }
            const obj = {};
            obj['stream'] = stream;
            obj['name'] = 'name';
            obj['cameraStatus'] = true;
            obj['microphoneStatus'] = true;
            this.videos.push(obj);
            this.videos = [...new Map(this.videos.map(item =>
                [item['stream']['id'], item])).values()];
        },
        setVideoSettings(arr) {
          this.videos.forEach((item) => {
              const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
              if (find) {
                  item.name = find.name;
                  item.microphoneStatus = find.microphoneStatus;
              }
          })
        },
        changeMicrophoneStatus(arr) {
            this.videos.forEach((item) => {
                const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
                if (find) {
                    item.microphoneStatus = find.microphoneStatus;
                }
            })
        },
        changeCameraStatus(arr) {
            this.videos.forEach((item) => {
                const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
                if (find) {
                    item.cameraStatus = find.cameraStatus;
                }
            })
        },
        removeVideoByStreamId(mediaStreamId) {
            const findIndex = this.videos.findIndex((videos) => videos.stream.id == mediaStreamId);
            if (findIndex > -1) {
                this.videos.splice(findIndex, 1);
            }
        },
        async addMediaDevices(constraints) {
            try {
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        mediaStreamId.value = stream.id;
                        setPeer();
                        this.addVideoStream(stream, true, userId.value);
                        callEvent(stream);
                        useSocket().userConnected(stream);
                    })
            } catch (error) {
                dialogError.value = true;
                throw new Error(error);
            }
        }
    }
})
